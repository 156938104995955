<template>
  <div>
    <v-sheet class="pa-8">
      <v-row>
        <v-col
          v-if="stockAdjustmentData"
          cols="12">
          <div class="d-flex align-center">
            <v-icon @click="goBack">
              mdi-arrow-left
            </v-icon>
            <p class="text-h5 mb-0 ml-3">
              ใบคำนวณเลขที่ {{ stockAdjustmentData.code || '-' }}
            </p>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="stockAdjustmentSku === 0"
              class="mr-3"
              :loading="exportLoading"
              color="success"
              @click="exportCsv('all')">
              Summary Report
            </v-btn>
          </div>
        </v-col>

        <v-col
          v-if="stockAdjustmentData"
          cols="12">
          <p>
            <span class="font-weight-bold mr-2">ใบสั่งนับเลขที่ : </span>
            <span class="mr-2">{{ stockAdjustmentData.stockCountCode || '-' }}</span>
          </p>
          <p>
            <span class="font-weight-bold mr-2">รายการนับที่นำมาคำนวณ : </span>
            <span class="mr-2">
              {{ stockCounterCodes }}
            </span>
          </p>
          <p>
            <span class="font-weight-bold mr-2">Stock Time : </span>
            <span class="mr-2">
              {{ stockAdjustmentData.stockTime | dateTimeUserFormat }}
            </span>
          </p>
        </v-col>

        <v-col cols="12">
          <v-tabs
            v-model="tabIndex"
            @change="handleTabChange">
            <v-tab
              v-for="(item, index) in tabs"
              :key="`${item.value}-${index}`">
              <p class="mb-0">
                {{ item.label }}
              </p>
            </v-tab>
          </v-tabs>
          <v-divider></v-divider>
        </v-col>
        <v-col
          v-if="tabs[tabIndex].skuType === 'excessive'"
          cols="12"
          class="d-flex">
          <h3>
            ของเกิน ({{ diffItems.length | showNumberFormat }})
          </h3>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-3"
            :loading="exportLoading"
            :disabled="diffItems.length === 0"
            color="success"
            @click="exportCsv('excessive')">
            EXPORT TO CSV
          </v-btn>
          <v-btn
            :loading="createNoteLoading"
            color="secondary"
            @click="confirmCreateDn">
            CREATE DN
          </v-btn>
        </v-col>
        <v-col
          v-if="tabs[tabIndex].skuType === 'lost'"
          cols="12"
          class="d-flex">
          <h3>
            ของหาย ({{ diffItems.length | showNumberFormat }})
          </h3>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-3"
            color="success"
            :disabled="diffItems.length === 0"
            :loading="exportLoading"
            @click="exportCsv('lost')">
            EXPORT TO CSV
          </v-btn>
          <v-btn
            :loading="createNoteLoading"
            color="secondary"
            @click="confirmCreateWn">
            CREATE WN
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items-per-page="-1"
            :items="diffItems"
            hide-default-footer
            item-key="skuId"
            class="elevation-1"
            :loading="loading"
            loading-text="Loading... Please wait">
            <template v-slot:header.note="{}">
              WN
            </template>
            <template v-slot:header.select="{}">
              <v-checkbox
                v-if="diffItems.length && itemLockedCount !== diffItems.length"
                v-model="diffHeaderSelect"
                color="secondary"
                hide-details
                class="shrink mr-2 mt-0"
                :disabled="createNoteLoading"
                @change="onSelectAllChange"></v-checkbox>
              <p
                v-else
                class="mb-0">
                Select
              </p>
            </template>
            <template v-slot:item.select="{ item }">
              <v-checkbox
                v-if="!noteItCantUse(item.currentNoteRef)"
                v-model="selectedItems"
                :disabled="createNoteLoading"
                color="secondary"
                :value="item"
                hide-details
                class="shrink mr-2 mt-0"></v-checkbox>
            </template>
            <template v-slot:item.stock="{ item }">
              {{ item.stock | showNumberFormat }}
            </template>
            <template v-slot:item.count="{ item }">
              {{ item.count | showNumberFormat }}
            </template>
            <template v-slot:item.diff="{ item }">
              {{ item.diff | showNumberFormat }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn
                v-if="item && noteItCantUse(item.currentNoteRef)"
                text
                color="indigo"
                @click="
                  item.currentNoteRef.type === 'dn'
                    ? openNewTab({
                      name: 'DeliveryNote',
                      query: {
                        id: item.currentNoteRef.id,
                        warehouse: stockAdjustmentData.warehouse.id
                      }
                    })
                    : openNewTab({
                      name: 'WithdrawNote',
                      query: {
                        id: item.currentNoteRef.id,
                        warehouse: stockAdjustmentData.warehouse.id
                      }
                    })">
                <span class="text-decoration-underline">{{ item.currentNoteRef.code }}</span>
              </v-btn>
            </template>
          </v-data-table>
          <div class="text-center mt-4">
            <v-pagination
              v-model="querySkusOptions.page"
              color="secondary"
              :length="querySkusOptions.totalPages"
              @input="handlePaginate"></v-pagination>
          </div>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import StockCountAdjustmentProvider from '@/resources/StockCountAdjustmentProvider'
import { mapActions } from 'vuex'
import InventoryProvider from '@/resources/InventoryProvider'

export default {
  components: { },
  data () {
    return {
      loading: true,
      stockCountAdjustmentProvider: new StockCountAdjustmentProvider(),
      inventoryProvider: new InventoryProvider(),
      tabIndex: 0,
      tabs: [
        { value: 'diffPlus', label: 'ของเกิน', skuType: 'excessive' },
        { value: 'diffMinus', label: 'ของหาย', skuType: 'lost' }
      ],
      status: [
        { value: '', label: 'ทั้งหมด', color: '' },
        { value: 'ready', label: 'พร้อมนับ', color: 'success' },
        { value: 'waiting', label: 'รอตรวจสอบ', color: 'indigo' },
        { value: 'completed', label: 'เสร็จสิ้น', color: 'grey' }
      ],
      stockAdjustmentData: null,
      headers: [
        {
          text: 'Select',
          align: 'left',
          value: 'select',
          sortable: false,
          width: '30'
        },
        {
          text: 'name',
          align: 'left',
          value: 'name',
          sortable: false
        },
        {
          text: 'code',
          align: 'left',
          value: 'code',
          sortable: false
        },
        {
          text: 'Stock',
          align: 'right',
          value: 'stock',
          sortable: true
        },
        {
          text: 'Count',
          align: 'right',
          value: 'count',
          sortable: true
        },
        {
          text: 'Diff',
          align: 'right',
          value: 'diff',
          sortable: true
        },
        {
          text: 'Remark/Location',
          align: 'left',
          value: 'remark',
          sortable: false
        },
        { text: 'Actions', align: 'center', value: 'actions', sortable: false }
      ],
      createNoteLoading: false,
      querySkusOptions: {
        page: 1,
        limit: 100,
        totalPages: 0,
        totalItems: 0,
        stockCountAdjustmentId: this.$route.params.stockAdjustmentId,
        skuType: 'excessive'
      },
      diffItems: [],
      selectedItems: [],
      diffHeaderSelect: false,
      exportLoading: false,
      stockAdjustmentNote: null,
      pageIsNeverHidden: true,
      noteStatus: []
    }
  },
  computed: {
    stockCounterCodes () {
        if (!this.stockAdjustmentData && this.stockAdjustmentData.stockCounters.length) return ''
        return this.stockAdjustmentData.stockCounters.map((stockCounter) => stockCounter.stockCounterCode).join(', ')
    },
    itemLockedCount () {
      return this.diffItems.filter((item) => this.noteItCantUse(item.currentNoteRef)).length
    },
    stockAdjustmentSku () {
      return this.stockAdjustmentData?.totalDiffSku ?? 0
    }
  },
  watch: {
    selectedItems: {
      handler (val) {
        const selectedItems = this.diffItems.filter((item) => !this.noteItCantUse(item.currentNoteRef))
        if (val.length > 0 && val.length === selectedItems.length) {
          this.diffHeaderSelect = true
        } else {
          this.diffHeaderSelect = false
        }
      },
      deep: true
    }
  },
  async mounted () {
    document.addEventListener('visibilitychange', async () => {
      if (document.visibilityState !== 'hidden' && this.stockAdjustmentNote) {
        await this.fetchStockAdjustmentById(this.$route.params.stockAdjustmentId)
        await this.getNoteStatus()
        await this.fetchStockAdjustmentSkuById()
        this.selectedItems = []
        this.pageIsNeverHidden = true
        this.stockAdjustmentNote = null
      }
    })
    await this.fetchStockAdjustmentById(this.$route.params.stockAdjustmentId)
    await this.getNoteStatus()
    await this.fetchStockAdjustmentSkuById()
  },
  beforeDestroy () {
    document.removeEventListener('visibilitychange', () => {})
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal'
    }),
    handleTabChange (tabIndex) {
      this.querySkusOptions.page = 1
      this.querySkusOptions.limit = 100
      this.querySkusOptions.skuType = this.tabs[tabIndex].skuType
      this.fetchStockAdjustmentSkuById()
    },
    handlePaginate () {
      this.fetchStockAdjustmentSkuById()
    },
    getStatusLabel (status, attribute = 'label') {
      return this.status.find((st) => st.value === status)[attribute] ?? '-'
    },
    async fetchStockAdjustmentById (stockAdjId) {
      this.loading = true
      try {
        const { data } = await this.stockCountAdjustmentProvider.getById(stockAdjId)
        this.stockAdjustmentData = data
      } catch (error) {
        console.error('fetchStockCountById', error)
      } finally {
        this.loading = false
      }
    },
    async fetchStockAdjustmentSkuById () {
      this.loading = true
      try {
        this.diffItems = []
        const { data } = await this.stockCountAdjustmentProvider.getStockAdjustmentSkus(this.querySkusOptions)
        this.querySkusOptions.totalPages = data.pages
        this.querySkusOptions.totalItems = data.total
        this.diffItems = data.results
        this.selectedItems = []
        this.diffHeaderSelect = false
      } catch (error) {
        console.error('fetchStockAdjustmentSkuById', error)
      } finally {
        this.loading = false
      }
    },
    onSelectAllChange (changeVal) {
      if (changeVal) {
        this.selectedItems = []
        this.selectedItems = this.diffItems.filter((item) => !this.noteItCantUse(item.currentNoteRef))
      } else {
        this.selectedItems = []
      }
    },
    async exportCsv (skuType = 'all') {
      this.exportLoading = true
      try {
        const { data } = await this.stockCountAdjustmentProvider.exportStockAdjustmentSkusReport({
          stockCountAdjustmentId: this.stockAdjustmentData.id,
          stockCountAdjustmentCode: this.stockAdjustmentData.code,
          skuType
        })
        this.download(data)
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[GETTING STOCK ADJUSTMENT INFO ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.exportLoading = false
      }
    },
    download (url, fileName) {
      let tmpFilename = fileName

      if (!fileName) {
        tmpFilename = this.$dayjs().format('YYYYMMDDHHmmss')
      }
      const downloadLink = document.createElement('a')
      downloadLink.href = url
      downloadLink.download = tmpFilename
      downloadLink.click()
    },
    confirmCreateDn () {
      if (this.stockAdjustmentNote) {
         this.setModal({
          value: true,
          title: 'การสร้างใบ DN ผิดพลาด',
          message: 'ไม่สามารถสร้างใบ DN ได้จนกว่าจะดำเนินการสร้างใบ DN ในแท็บที่ค้างอยู่ให้เสร็จสิ้น',
          confirmText: 'ตกลง',
          confirmType: 'error',
          confirmOnly: true
        })
        return
      }
      if (this.selectedItems.length > 0) {
        this.setModal({
          value: true,
          title: 'สร้างใบ DN',
          message: 'ต้องการสร้างใบ DN หรือไม่',
          confirmText: 'ต้องการ',
          confirmType: 'success',
          cancelType: '',
          cancelText: 'ยกเลิก',
          onConfirm: () => this.createDn()
        })
      } else {
        this.setModal({
          value: true,
          title: 'การสร้างใบ DN ผิดพลาด',
          message: 'กรุณาเลือกสินค้าก่อนทำการสร้างใบ DN',
          confirmText: 'ตกลง',
          confirmType: 'error',
          confirmOnly: true
        })
      }
    },
    confirmCreateWn () {
      if (this.stockAdjustmentNote) {
         this.setModal({
          value: true,
          title: 'การสร้างใบ WN ผิดพลาด',
          message: 'ไม่สามารถสร้างใบ DN ได้จนกว่าจะดำเนินการสร้างใบ WN ในแท็บที่ค้างอยู่ให้เสร็จสิ้น',
          confirmText: 'ตกลง',
          confirmType: 'error',
          confirmOnly: true
        })
        return
      }
      if (this.selectedItems.length > 0) {
        this.setModal({
          value: true,
          title: 'สร้างใบ WN',
          message: 'ต้องการสร้างใบ WN หรือไม่',
          confirmText: 'ต้องการ',
          confirmType: 'success',
          cancelType: '',
          cancelText: 'ยกเลิก',
          onConfirm: () => this.createWn()
        })
      } else {
        this.setModal({
          value: true,
          title: 'การสร้างใบ WN ผิดพลาด',
          message: 'กรุณาเลือกสินค้าก่อนทำการสร้างใบ WN',
          confirmText: 'ตกลง',
          confirmType: 'error',
          confirmOnly: true
        })
      }
    },
    async createDn () {
      try {
        this.createNoteLoading = true
        this.stockAdjustmentNote = null
        const payload = {
          stockCountAdjustment: this.stockAdjustmentData.id,
          note: `ADJ.STOCK AS AT ${this.$options.filters.dateTimeFormat(this.stockAdjustmentData.stockTime)} จากการนับสต๊อกสินค้า`,
          reason: 'STOCK_COUNT',
          status: 'pending',
          skus: this.selectedItems,
          warehouse: this.stockAdjustmentData.warehouse,
          noteType: 'dn'
        }
        const { data: stockAdjustmentNote } = await this.stockCountAdjustmentProvider.createStockAdjustmentNote(payload)
        this.stockAdjustmentNote = stockAdjustmentNote
        this.pageIsNeverHidden = false
        this.openNewTab({
          name: 'DeliveryNote',
          query: {
            stockAdjustmentNoteId: stockAdjustmentNote.id,
            warehouse: stockAdjustmentNote.warehouse.id
          }
        })
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: 'การสร้างใบนำเข้าผิดพลาดกรุณาตรวจสอบ',
          type: 'error'
        })
      } finally {
          this.createNoteLoading = false
      }
    },
    async createWn () {
      try {
        this.createNoteLoading = true
        this.stockAdjustmentNote = null
        const payload = {
          stockCountAdjustment: this.stockAdjustmentData.id,
          note: `ADJ.STOCK AS AT ${this.$options.filters.dateTimeFormat(this.stockAdjustmentData.stockTime)} จากการนับสต๊อกสินค้า`,
          reason: 'STOCK_COUNT',
          status: 'pending',
          skus: this.selectedItems,
          warehouse: this.stockAdjustmentData.warehouse,
          noteType: 'wn'
        }
        const { data: stockAdjustmentNote } = await this.stockCountAdjustmentProvider.createStockAdjustmentNote(payload)
        this.stockAdjustmentNote = stockAdjustmentNote
        this.pageIsNeverHidden = false
        this.openNewTab({
          name: 'WithdrawNote',
          query: {
            stockAdjustmentNoteId: stockAdjustmentNote.id,
            warehouse: stockAdjustmentNote.warehouse.id
          }
        })
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: 'การสร้างใบนำออกผิดพลาดกรุณาตรวจสอบ',
          type: 'error'
        })
      } finally {
        this.createNoteLoading = false
      }
    },
    openNewTab ({ name, query }) {
      const routeData = this.$router.resolve({ name, query })
      window.open(routeData.href, '_blank')
    },
    async fetchAdjustmentNote (stockAdjustmentNoteId, noteType) {
      try {
        const { data: stockAdjustmentNote } = await this.stockCountAdjustmentProvider.getStockAdjustmentNote({
          stockAdjustmentNoteId,
          noteType
        })
        this.stockAdjustmentNote = stockAdjustmentNote
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: 'เกิดข้อผิดพลาด',
          type: 'error'
        })
      }
    },
    async getNoteStatus () {
      const stockAdjustmentsData = this.stockAdjustmentData
      const dnList = stockAdjustmentsData.notes.filter((note) => note.noteType === 'dn').map((note) => note.noteId)
      const wnList = stockAdjustmentsData.notes.filter((note) => note.noteType === 'wn').map((note) => note.noteId)
      const [dnStatus, wnStatus] = await Promise.all([
        this.inventoryProvider.getDeliveryNoteStatus({ ids: dnList }),
        this.inventoryProvider.getWithdrawNoteStatus({ ids: wnList })
      ])
      this.noteStatus = [...dnStatus.data, ...wnStatus.data]
    },
    noteItCantUse (currentNoteRef) {
      if (!currentNoteRef) return false
      const noteData = this.noteStatus.find((note) => note.code === currentNoteRef.code)
      return noteData && ['approved', 'pending'].includes(noteData.status)
    },
    goBack () {
      if (window.history.length > 1) {
        this.$router.go(-1)
      } else {
        this.$router.push({ name: 'StockCountPreview', params: { id: this.$route.params.stockCountId } })
      }
    }
  }
}
</script>
